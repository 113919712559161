/*
 * 年(Y) 可用1-4个占位符
 * 月(m)、日(d)、小时(H)、分(M)、秒(S) 可用1-2个占位符
 * 星期(W) 可用1-3个占位符
 * 季度(q为阿拉伯数字，Q为中文数字)可用1或4个占位符
 *
 * let date = new Date()
 * formatDate(date, "YYYY-mm-dd HH:MM:SS")           // 2020-02-09 14:04:23
 * formatDate(date, "YYYY-mm-dd HH:MM:SS Q")         // 2020-02-09 14:09:03 一
 * formatDate(date, "YYYY-mm-dd HH:MM:SS WWW")       // 2020-02-09 14:45:12 星期日
 * formatDate(date, "YYYY-mm-dd HH:MM:SS QQQQ")      // 2020-02-09 14:09:36 第一季度
 * formatDate(date, "YYYY-mm-dd HH:MM:SS WWW QQQQ")  // 2020-02-09 14:46:12 星期日 第一季度
 */
export function formatDate(date, format) {
	let we = date.getDay(); // 星期
	let qut = Math.floor((date.getMonth() + 3) / 3).toString(); // 季度
	const opt = {
		'Y+': date.getFullYear().toString(), // 年
		'm+': (date.getMonth() + 1).toString(), // 月(月份从0开始，要+1)
		'd+': date.getDate().toString(), // 日
		'H+': date.getHours().toString(), // 时
		'M+': date.getMinutes().toString(), // 分
		'S+': date.getSeconds().toString(), // 秒
		'q+': qut, // 季度
	};
	const week = {
		// 中文数字 (星期)
		0: '日',
		1: '一',
		2: '二',
		3: '三',
		4: '四',
		5: '五',
		6: '六',
	};
	const quarter = {
		// 中文数字（季度）
		1: '一',
		2: '二',
		3: '三',
		4: '四',
	};
	if (/(W+)/.test(format)) {
		format = format.replace(RegExp.$1, RegExp.$1.length > 1 ? (RegExp.$1.length > 2 ? '星期' + week[we] : '周' + week[we]) : week[we]);
	}
	if (/(Q+)/.test(format)) {
		// 输入一个Q，只输出一个中文数字，输入4个Q，则拼接上字符串
		format = format.replace(RegExp.$1, RegExp.$1.length == 4 ? '第' + quarter[qut] + '季度' : quarter[qut]);
	}
	for (let k in opt) {
		let r = new RegExp('(' + k + ')').exec(format);
		if (r) {
			// 若输入的长度不为1，则前面补零
			format = format.replace(r[1], RegExp.$1.length == 1 ? opt[k] : opt[k].padStart(RegExp.$1.length, '0'));
		}
	}
	return format;
}

/**
 * 10秒：  10 * 1000
 * 1分：   60 * 1000
 * 1小时： 60 * 60 * 1000
 * 24小时：60 * 60 * 24 * 1000
 * 3天：   60 * 60* 24 * 1000 * 3
 *
 * let data = new Date()
 * formatPast(data)                                           // 刚刚
 * formatPast(data - 11 * 1000)                               // 11秒前
 * formatPast(data - 2 * 60 * 1000)                           // 2分钟前
 * formatPast(data - 60 * 60 * 2 * 1000)                      // 2小时前
 * formatPast(data - 60 * 60 * 2 * 1000)                      // 2小时前
 * formatPast(data - 60 * 60 * 71 * 1000)                     // 2天前
 * formatPast("2020-06-01")                                   // 2020-06-01
 * formatPast("2020-06-01", "YYYY-mm-dd HH:MM:SS WWW QQQQ")   // 2020-06-01 08:00:00 星期一 第二季度
 */
export function formatPast(param, format = 'YYYY-mm-dd') {
	// 传入格式处理、存储转换值
	let t, s;
	// 获取js 时间戳
	let time = new Date().getTime();
	// 是否是对象
	typeof param === 'string' || 'object' ? (t = new Date(param).getTime()) : (t = param);
	// 当前时间戳 - 传入时间戳
	time = Number.parseInt(time - t);
	if (time < 10000) {
		// 10秒内
		return '刚刚';
	} else if (time < 60000 && time >= 10000) {
		// 超过10秒少于1分钟内
		s = Math.floor(time / 1000);
		return `${s}秒前`;
	} else if (time < 3600000 && time >= 60000) {
		// 超过1分钟少于1小时
		s = Math.floor(time / 60000);
		return `${s}分钟前`;
	} else if (time < 86400000 && time >= 3600000) {
		// 超过1小时少于24小时
		s = Math.floor(time / 3600000);
		return `${s}小时前`;
	} else if (time < 259200000 && time >= 86400000) {
		// 超过1天少于3天内
		s = Math.floor(time / 86400000);
		return `${s}天前`;
	} else {
		// 超过3天
		let date = typeof param === 'string' || 'object' ? new Date(param) : param;
		return formatDate(date, format);
	}
}

/**
 * formatAxis(new Date())   // 上午好
 */
export function formatAxis(param) {
	let hour = new Date(param).getHours();
	if (hour < 6) {
		return '凌晨好';
	} else if (hour < 9) {
		return '早上好';
	} else if (hour < 12) {
		return '上午好';
	} else if (hour < 14) {
		return '中午好';
	} else if (hour < 17) {
		return '下午好';
	} else if (hour < 19) {
		return '傍晚好';
	} else if (hour < 22) {
		return '晚上好';
	} else {
		return '夜里好';
	}
}
/**
 * getAge(param)   // 上午好
 */
function getDate(date) {
	let d = new Date(date);
	// 将日期设置为下月一号
	d.setMonth(d.getMonth() + 1);
	d.setDate('1');
	// 获取本月最后一天
	d.setDate(d.getDate() - 1);
	return d.getDate();
}
export function getAge(birthday) {
	// 先截取到字符串中的年、月、日
	let selectYear = birthday.split('-')[0];
	let selectMonth = birthday.split('-')[1];
	let selectDay = birthday.split('-')[2];
	// 得到当前时间的年、月、日
	let cal = new Date();
	let yearNow = cal.getFullYear();
	let monthNow = cal.getMonth() + 1;
	let dayNow = cal.getDate();

	// 用当前年月日减去生日年月日
	let yearMinus = yearNow - selectYear;
	let monthMinus = monthNow - selectMonth;
	let dayMinus = dayNow - selectDay;

	let age = '';
	if (yearMinus < 0) {
		return '生日不可小于当前时间';
	} else {
		if (yearMinus === 0) {
			if (monthMinus < 0) {
				return '生日不可小于当前时间';
			} else {
				if (monthMinus > 0) {
					if (dayMinus >= 0) {
						return monthMinus + '个月' + (dayMinus > 0 ? dayMinus + '天' : '');
					}
					return (monthMinus - 1 === 0 ? '' : monthMinus - 1 + '个月') + (dayNow + (getDate(birthday) - selectDay)) + '天';
				} else {
					if (dayMinus < 0) {
						return '生日不可小于当前时间';
					}
					return dayMinus + '天';
				}
			}
		} else {
			age = yearMinus + '岁';
			return age;
			// if (monthMinus === 0) {
			// 	if (dayMinus >= 0) {
			// 		return age + (dayMinus === 0 ? '' : dayMinus + '天')
			// 	}
			// 	return (yearMinus - 1 === 0 ? '' : yearMinus - 1 + '岁') + '11个月'
			// } else if (monthMinus > 0) {
			// 	age += (dayMinus >= 0 ? monthMinus + '个月' : (monthMinus - 1 > 0 ? monthMinus + '个月' : (getDate(birthday) - selectDay + dayNow + '天')))
			// 	return age;
			// } else {
			// 	return (yearMinus - 1 === 0 ? '' : yearMinus - 1 + '岁') + (12 - selectMonth + monthNow) + '个月'
			// }
		}
	}
}

/**
 * getCurDay  // 获取当天日期
 */
export function getCurDay() {
	var datetime = new Date();
	var year = datetime.getFullYear();
	var month = datetime.getMonth() + 1 < 10 ? '0' + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
	var date = datetime.getDate() < 10 ? '0' + datetime.getDate() : datetime.getDate();
	return `${year}-${month}-${date}`;
}

/** 格式化时间戳 */
export function formatTimestamp(timestamp) {
	// 此处时间戳以毫秒为单位
	// let date = new Date(parseInt(timestamp));
	let date = timestamp;
	let Year = date.getFullYear();
	let Moth = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
	let Day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
	let Hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
	let Minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
	let Sechond = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
	let GMT = Year + '.' + Moth + '.' + Day + ' ' + Hour + ':' + Minute + ':' + Sechond;
	return GMT;
}
export function thousandComma(num) {
	if (!num) num = 0;
	num = num.toString();
	if (num.length <= 2) {
		num = ('00' + num).slice(-3);
	}
	let n = num.length - 2;
	let s1 = num.substring(0, n);
	let s2 = num.substring(n, num.length);
	num = s1 + '.' + s2;
	num = num.split('.'); // 分隔小数点
	var arr = num[0].split('').reverse(); // 转换成字符数组并且倒序排列
	var res = [];
	for (var i = 0, len = arr.length; i < len; i++) {
		if (i % 3 === 0 && i !== 0) {
			res.push(','); // 添加分隔符
		}
		res.push(arr[i]);
	}
	res.reverse(); // 再次倒序成为正确的顺序
	if (num[1]) {
		// 如果有小数的话添加小数部分
		res = res.join('').concat('.' + num[1]);
	} else {
		res = res.join('');
	}
	return res;
}
export default {
	thousandComma,
};
// 价格筛查
export function filterPrice(priceCny, priceCreditScore) {
	let price = 0;
	if (priceCny != 0) {
		price = thousandComma(priceCny) || 0;
	}
	if (price !== 0 && priceCreditScore !== 0) {
		return `￥${price}+${priceCreditScore}代币`;
	} else if (price !== 0 && priceCreditScore === 0) {
		return `￥${price}`;
	} else if (price === 0 && priceCreditScore !== 0) {
		return `${priceCreditScore}代币`;
	} else {
		return '免费';
	}
}
